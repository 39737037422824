import { ReactNode } from 'react';
import {
    Box,
    Flex,
    Avatar,
    HStack,
    Link,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Cookies from 'universal-cookie';
import { isLogged } from '../utils/isLogged';
import { decode } from '../utils/decode';

const Links = [{ name: 'Home', href: '/' }, { name: 'Pricing', href: '/#pricing' }, { name: 'Upgrade', href: '/upgrade' }, { name: 'Renew', href: '/renew' }, { name: 'Discord', href: 'https://discord.gg/UbMTGTNQPb' }];

const NavLink = ({ children }) => (
    <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        href={`${children.href}`}>
        {children.name}
    </Link>
);

const cookies = new Cookies()

export default function Navbar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const authToken = cookies.get('authToken')
    return (
        <>
            <Box bg={useColorModeValue('whiteAlpha.200', 'gray.900')} alignItems={'center'} px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box alignItems={'center'}><img src="https://i.imgur.com/usl9ptx.png" alt="" width={50} /></Box>
                        <HStack
                            as={'nav'}
                            spacing={4}
                            display={{ base: 'none', md: 'flex' }}>
                            {Links.map((link) => (
                                <NavLink key={link.name} href={link.href}>{link}</NavLink>
                            ))}
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        {decode(authToken) ?
                            <>
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rounded={'full'}
                                        variant={'link'}
                                        cursor={'pointer'}
                                        minW={0}>
                                        <Avatar
                                            size={'sm'}
                                            src={
                                                'https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg'
                                            }
                                        />
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem as={Link} href="/dashboard">Dashboard</MenuItem>
                                        <MenuItem as={Link} href="/payments">Payments</MenuItem>

                                        <MenuItem as={Link} href="/upgrades">Upgrades</MenuItem>
                                        <MenuItem as={Link} href="/renewals">Renewals</MenuItem>
                                        <MenuDivider />
                                        <MenuItem as={Link} href="/logout" color={'red.500'}>Logout</MenuItem>

                                    </MenuList>
                                </Menu>
                            </> : <>
                                <Button rightIcon={<ArrowForwardIcon />} colorScheme='teal' variant='outline' paddingRight={'7px'} marginRight={'10px'} as={Link} href='/login'>
                                    Log in
                                </Button>
                                <Button colorScheme='blue' size='md' as={Link} href='/register'>
                                    Register
                                </Button>
                            </>}
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavLink key={link}>{link}</NavLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}