import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies()

export function checkSession() {
    const token = cookies.get('authToken')

    try {
        const decode = jwt_decode(token)

        const user = decode;

        return user.id;
    } catch (error) {
        return
    }
}