import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import Cookies from 'universal-cookie'

export function Login() {

    const toast = useToast()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState("")

    const handleLogin = async (e) => {
        e.preventDefault()

        if (!email || !password) {
            return toast({
                title: 'Error',
                description: "Please, fill in all the form fields.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

        try {
            setIsLoading(true)

            const res = await fetch('https://upgradespy.store/api/v1/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            }).then((res) => res.json())

            if (res.status === 'success') {
                toast({
                    title: 'Success',
                    description: res.message + '.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

                const cookie = new Cookies()
                cookie.set('authToken', res.token, {
                    path: '/',
                    secure: true,
                    sameSite: 'lax'
                })

                return window.location.href = '/dashboard'
            } else {
                return toast({
                    title: 'Error',
                    description: res.message + '.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true
                })
            }
        } catch (error) {
            return toast({
                title: 'Error',
                description: "Unexpected error ocurred, contact our administrators.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <Flex
            minH={'80vh'}
            align={'center'}
            justify={'center'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Heading as="h1" fontSize="4xl">
                    Log in
                </Heading>
                <Box py={12}
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>

                    <Stack spacing={4} width={400}>
                        <form id="login-form">
                            <FormControl id="email">
                                <FormLabel>Email address</FormLabel>
                                <Input type="email" placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </FormControl>
                            <FormControl id="password">
                                <FormLabel>Password</FormLabel>
                                <Input type="password" placeholder='Enter password' value={password} onChange={(e) => setPassword(e.target.value)} required />
                            </FormControl>
                            <Stack spacing={10}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'start'}
                                    justify={'space-between'}>
                                </Stack>
                                <Button
                                    isLoading={isLoading}
                                    loadingText={'Logging in'}
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    onClick={handleLogin}>
                                    Sign in
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Box>
            </Stack >
        </Flex >
    );
}