import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
export function isLogged(token) {
    const cookies = new Cookies()
    try {
        const user = jwt_decode(token)

        const { exp } = user;

        if (exp <= Date.now() / 1000) {
            cookies.remove('authToken', {
                path: '/'
            })
            return false;
        } else {
            return true;
        }
    } catch (error) {
        return false;
    }

}