import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    useToast,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Radio,
    RadioGroup
} from '@chakra-ui/react';

import Cookies from 'universal-cookie'
import { isLogged } from '../utils/isLogged';
import { FaCheckCircle } from 'react-icons/fa';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';

const cookies = new Cookies();

function PriceWrapper({ children }) {
    return (
        <Box
            mb={4}
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderColor={useColorModeValue('gray.200', 'gray.500')}
            borderRadius={'xl'}>
            {children}
        </Box>
    );
}

function PaymentModal(props) {
    const toast = useToast()
    const { isOpen, onClose, data } = props;
    const [isLoading, setIsLoading] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState('crypto')

    const createPaymentLink = async (e) => {
        e.preventDefault()

        try {
            setIsLoading(true)
            const res = await fetch('https://upgradespy.store/api/v1/payments/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: '868ede7349e4-0a4417-f83d85-7cd11fb6ae9c', ...data })
            }).then((res) => res.json())

            if (res.status === 'success') window.location.href = res.link
            if (res.status === 'error') {
                return toast({
                    title: 'Error',
                    description: 'Unexpected error has ocurred while creating the payment, contact our administrators.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true
                })
            }
        } catch (error) {
            return toast({
                title: 'Error',
                description: "Unexpected error ocurred, contact our administrators.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Payment for {data.ordering} Plan</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Delivery Email</FormLabel>
                        <Input placeholder={data.email} readOnly />
                    </FormControl>
                    <Text as={'p'} paddingTop={'5px'} paddingBottom={'10px'}>Total of <b>${data.amount}</b> ({data.keys} keys)</Text>

                    <FormControl>
                        <FormLabel>Choose a payment method</FormLabel>
                        <RadioGroup onChange={setPaymentMethod} value={paymentMethod}>
                            <Stack direction='row'>
                                <Radio value='crypto'>Cryptocurrency</Radio>
                                <Radio value='paypal' disabled>PayPal</Radio>
                                <Radio value='stripe' disabled>Stripe (Credit Card)</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={(e) => createPaymentLink(e)} isLoading={isLoading} loadingText='Creating'>
                        Pay ${data.amount}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default function Pricing() {
    const toast = useToast()
    const [isOpen, setIsOpen] = useState(false);
    const [paymentData, setPaymentData] = useState({ ordering: 'Single', keys: '1', email: 'test@email.com', amount: 10, userId: 'USER' });

    const handlePayment = async (e, plan) => {
        e.preventDefault()

        const authToken = cookies.get("authToken");
        const logged = isLogged(authToken);

        if (!logged) {
            return toast({
                title: 'Error',
                description: 'You must be logged in to purchase a plan.',
                status: 'error',
                duration: 9000,
                isClosable: true
            })
        }

        const { email, id } = jwtDecode(authToken)
        console.log({ email, id })


        let price = {
            'Single': 9.99,
            'Duo': 17.99,
            'Family': 29.99,
        }

        let keys = {
            'Single': 1,
            'Duo': 2,
            'Family': 5
        }

        const data = { ordering: plan, keys: keys[plan], email, amount: price[plan], userId: id }

        setPaymentData(data);
        setIsOpen(true);
    }

    const handleCloseModal = () => {
        setIsOpen(false);
    }
    return (
        <Box py={12} id='pricing'>
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    Offering you cheapest pricing plans
                </Heading>
                <Text fontSize="lg" color={'gray.500'}>
                    All plans come with lifetime warranty and free replacements whenever needed.
                </Text>
            </VStack>
            <Stack
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}>

                <PaymentModal isOpen={isOpen} onClose={handleCloseModal} data={paymentData} />

                <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            Single
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                                $
                            </Text>
                            <Text fontSize="5xl" fontWeight="900">
                                9,99
                            </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                1x Upgrade Key
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Lifetime Warranty
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Fully Automated
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="blue" variant="outline" onClick={(e) => handlePayment(e, 'Single')}>
                                Purchase
                            </Button>
                        </Box>
                    </VStack>
                </PriceWrapper>

                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={useColorModeValue('blue.300', 'blue.700')}
                                px={3}
                                py={1}
                                color={useColorModeValue('gray.900', 'gray.300')}
                                fontSize="sm"
                                fontWeight="600"
                                rounded="xl">
                                Most Popular
                            </Text>
                        </Box>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                Duo
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="900">
                                    17,99
                                </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    2x Upgrade Keys
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Lifetime Warranty
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Fully Automated
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button w="full" colorScheme="blue" onClick={(e) => handlePayment(e, 'Duo')} >
                                    Purchase
                                </Button>
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper >
                <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            Family
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                                $
                            </Text>
                            <Text fontSize="5xl" fontWeight="900">
                                29,99
                            </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                5x Upgrade Keys
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Lifetime Warranty
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Fully Automated
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="blue" variant="outline" onClick={(e) => handlePayment(e, 'Family')} >
                                Purchase
                            </Button>
                        </Box>
                    </VStack>
                </PriceWrapper>
            </Stack >
        </Box >
    );
}