import { Box, Heading, Table, TableCaption, TableContainer, Tbody, Thead, Tr, VStack, Text, Flex, Th, Td, Tfoot, Badge } from "@chakra-ui/react";

export function Renewals({ renewals }) {
    return (
        <>
            <Box width="100%" overflowX="auto" py={12}>
                <VStack spacing={2} textAlign="center" paddingBottom={{ base: 4, md: 12 }}>
                    <Heading as="h1" fontSize={{ base: "2xl", md: "4xl" }}>
                        Your Renewals
                    </Heading>
                    <Text fontSize={{ base: "md", md: "lg" }} color={"gray.500"}>
                        Here you have a list of all your renewals that you have made on our site along with some information.
                    </Text>
                </VStack>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Country</Th>
                            <Th>Date</Th>
                            <Th>Key</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {renewals ? renewals.map((renew) => {
                            return (
                                <Tr key={renew.id}>
                                    <Td>{renew.country}</Td>
                                    <Td>{renew.last_renew ? new Date(renew.last_renew.date).toLocaleDateString() : 'N/A'}</Td>
                                    <Td>{renew.key}</Td>
                                    <Td>
                                        <Badge variant='solid' colorScheme={renew.status === 'Renewed' ? 'green' : renew.status === 'Renewing' ? 'yellow' : 'red'}>
                                            {renew.status}
                                        </Badge>
                                    </Td>
                                </Tr>
                            )
                        }) : <></>}
                    </Tbody>
                </Table>
            </Box>

        </>

    )
}