import jwtDecode from "jwt-decode";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { decode } from './utils/decode'

const cookies = new Cookies();

export default function ProtectedRoutes({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const authToken = cookies.get("authToken");

        const d = decode(authToken)

        if (d) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
}