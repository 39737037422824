import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';

export function Home() {
    return (
        <>
            <Hero />
            <Features />
            <Pricing />
        </>
    )
}