import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

export function decode(token) {

    const cookies = new Cookies()

    try {
        const decode = jwt_decode(token)

        const user = decode;

        const expiration = user.exp;

        if (expiration <= Date.now() / 1000) {
            cookies.remove('authToken', {
                path: '/'
            })
            return false;
        } else {
            return true;
        }
    } catch (error) {
        cookies.remove('authToken', {
            path: '/'
        })
        return false;
    }
}