import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Container,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home';
import { Upgrade } from './pages/Upgrade';
import { Renew } from './pages/Renew';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Logout } from './pages/Logout';
import { Dashboard } from './pages/Dashboard';
import { Payments } from './pages/Payments';
import { Upgrades } from './pages/Upgrades';
import { Renewals } from './pages/Renewals';
import ProtectedRoutes from './ProtectedRoutes';
import { useEffect } from 'react';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { checkSession } from './utils/checkSession';

const cookies = new Cookies()
function App() {
  const [userData, setUserData] = useState({})
  const [upgrades, setUpgrades] = useState(null)
  const [payments, setPayments] = useState(null)
  const [renewals, setRenewals] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const authToken = cookies.get('authToken')
      const id = checkSession()

      if (!id) return;

      const res = await fetch('https://upgradespy.store/api/v1/admin/user_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: id })
      }).then((res) => res.json())

      setUserData(res)
    }
    if (window.location.pathname === '/upgrades') {
      async function fetchUserUpgrades() {
        const authToken = cookies.get('authToken')
        const { id } = jwtDecode(authToken)

        if (!id) return;

        const res = await fetch('https://upgradespy.store/api/v1/admin/upgrades', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: id })
        }).then((res) => res.json())

        setUpgrades(res)
      }
      fetchUserUpgrades();
    }

    if (window.location.pathname === '/payments') {
      async function fetchUserPayments() {
        const authToken = cookies.get('authToken')
        const id = checkSession()

        if (!id) return;

        const res = await fetch('https://upgradespy.store/api/v1/payments/userPayments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: id })
        }).then((res) => res.json())

        setPayments(res.payments)
      }
      fetchUserPayments();
    }

    if (window.location.pathname === '/renewals') {
      async function fetchUserRenewals() {
        const authToken = cookies.get('authToken')
        const id = checkSession()

        if (!id) return;

        const res = await fetch('https://upgradespy.store/api/v1/admin/renewals', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: id })
        }).then((res) => res.json())

        setRenewals(res.renewals)
      }
      fetchUserRenewals();
    }

    fetchData()
  }, [])
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Container maxW={'container.xl'}>
          <Box textAlign="center" fontSize="xl">
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/upgrade" component={Upgrade} />
              <Route exact path="/renew" component={Renew} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/logout" component={Logout} />

              <ProtectedRoutes exact path="/dashboard" component={(props) => <Dashboard userData={userData} {...props} />} />
              <ProtectedRoutes exact path="/payments" component={(props) => <Payments payments={payments} {...props} />} />
              <ProtectedRoutes exact path="/upgrades" component={(props) => <Upgrades upgrades={upgrades} {...props} />} />
              <ProtectedRoutes exact path="/renewals" component={(props) => <Renewals renewals={renewals} {...props} />} />

              <Route exact path="*" />
            </Switch>
            <Footer />
          </Box>
        </Container>
      </Router>
    </ChakraProvider>
  );
}

export default App;
