import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies()

export function Logout() {
    const authToken = cookies.get('authToken')
    const remove = cookies.remove('authToken')
    if (!authToken) {
        return <Redirect to='/login' />
    }
    <Redirect to='/login' />
    { window.location.reload() }
}