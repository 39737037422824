import { Avatar, Box, Flex, Text, Heading, VStack } from "@chakra-ui/react";

const UserProfile = ({ avatar, username, userId, email, registrationDate, isReseller }) => {
    return (
        <Flex align="center">
            <Avatar size="xl" name={username} src={avatar} mr={8} />
            <Box>
                <Text fontSize="2xl" fontWeight="bold" mb={2}>{username}</Text>
                <Text fontSize="lg" mb={2}>User ID: {userId}</Text>
                <Text fontSize="lg" mb={2}>Email: {email}</Text>
                <Text fontSize="lg" mb={2}>Registration Date: {registrationDate}</Text>
                <Text fontSize="lg">Reseller: {isReseller ? "Yes" : "No"}</Text>
            </Box>
        </Flex>
    );
};

export function Dashboard({ userData }) {
    return (
        <>
            <Box py={{ base: 6, md: 12 }}>
                <VStack spacing={2} textAlign="center" paddingBottom={{ base: 4, md: 12 }}>
                    <Heading as="h1" fontSize={{ base: "2xl", md: "4xl" }}>
                        Your Account
                    </Heading>
                    <Text fontSize={{ base: "md", md: "lg" }} color={"gray.500"}>
                        Here is the most important information about your account.
                    </Text>
                </VStack>
                <Flex
                    direction={{ base: "column", md: "row" }}
                    align="center"
                    maxW={{ base: "100%", md: "600px" }}
                    mx="auto"
                >
                    <Avatar
                        size="2xl"
                        width={{ base: "120px", md: "200px" }}
                        height={{ base: "120px", md: "200px" }}
                        src={
                            userData.discord && userData.discord.avatar
                                ? `https://cdn.discordapp.com/avatars/${userData.discord.id}/${userData.discord.avatar}.png?size=512`
                                : userData.avatar
                        }
                        mr={{ base: 0, md: 8 }}
                        mb={{ base: 8, md: 0 }}
                    />
                    <Box paddingLeft={{ base: 0, md: "80px" }}>
                        <Text fontSize={{ base: "md", md: "lg" }} fontWeight="semibold">
                            {userData.username}
                        </Text>
                        <Text fontSize={{ base: "sm", md: "md" }} color="gray.500" mb={4}>
                            {userData.id}
                        </Text>
                        <Text fontSize={{ base: "sm", md: "md" }} mb={1}>
                            Role: <Text as="span"><b>{userData.role}</b></Text>
                        </Text>
                        <Text fontSize={{ base: "sm", md: "md" }} mb={1}>
                            Balance: <Text as="span"><b>${userData.balance}</b></Text>
                        </Text>
                        <Text fontSize={{ base: "sm", md: "md" }} mb={1}>
                            Email: <Text as="span"><b>{userData.email}</b></Text>
                        </Text>
                        <Text fontSize={{ base: "sm", md: "md" }} mb={1}>
                            Registered:{" "}
                            <Text as="span"><b>{new Date(userData.registedAt).toLocaleDateString()}</b></Text>
                        </Text>
                        <Text fontSize={{ base: "sm", md: "md" }} mb={4}>
                            Reseller:{" "}
                            <Text as="span"><b>{userData.reseller ? "Yes" : "No"}</b></Text>{" "}
                            <Text as="a" href="/#pricing" color="blue.500">
                                (Learn more)
                            </Text>
                        </Text>
                    </Box>
                </Flex>
            </Box>
        </>

    )
}