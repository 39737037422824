import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { FaCompass, FaMoneyBill, FaRobot } from 'react-icons/fa';
import {
    FcAbout,
    FcAssistant,
    FcClock,
    FcCollaboration,
    FcDonate,
    FcManager,
    FcTimeline,
    FcTreeStructure,
} from 'react-icons/fc';

const Card = ({ heading, description, icon }) => {
    return (
        <Box
            maxW={{ base: 'full', md: '275px' }}
            w={'full'}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={5}>
            <Stack align={'center'} spacing={2}>
                <Flex
                    w={16}
                    h={16}
                    align={'center'}
                    justify={'center'}
                    color={'white'}
                    rounded={'full'}
                    bg={useColorModeValue('gray.100', 'gray.700')}>
                    {icon}
                </Flex>
                <Box mt={2}>
                    <Heading size="md">{heading}</Heading>
                    <Text mt={1} fontSize={'sm'}>
                        {description}
                    </Text>
                </Box>
            </Stack>
        </Box>
    );
};

export default function Features() {
    return (
        <Box p={4}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
                    Why choose us?
                </Heading>
                <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
                    Check out why you should use our service instead of our competitors.
                    We guarantee you won't regret use our system as it's the best on market.
                </Text>
            </Stack>

            <Container maxW={'5xl'} mt={12}>
                <Flex flexWrap="wrap" gridGap={6} justify="center">
                    <Card
                        heading={'24/7 Support'}
                        icon={<Icon as={FcAssistant} w={10} h={10} />}
                        description={
                            'We have an experienced support team for any issues you face.'
                        }
                    />
                    <Card
                        heading={'Automated'}
                        icon={<Icon as={FaRobot} w={10} h={10} color={'blue.400'} />}
                        description={
                            'Our fully system is automated, no hassle for you when using our service!'
                        }
                    />
                    <Card
                        heading={'Cheap'}
                        icon={<Icon as={FaMoneyBill} w={10} h={10} color={'green.500'} />}
                        description={
                            'We have one of the cheapest pricing of the whole market.'
                        }
                    />
                    <Card
                        heading={'Worldwide'}
                        icon={<Icon as={FaCompass} w={10} h={10} color={'darkgoldenrod'} />}
                        description={
                            'Our upgrades work worldwide, no matters where you from.'
                        }
                    />
                    <Card
                        heading={'Lifetime Warranty'}
                        icon={<Icon as={FcClock} w={10} h={10} color={'orange.500'} />}
                        description={
                            'All our upgrades come with lifetime warranty and along free replacements.'
                        }
                    />
                    <Card
                        heading={'Secure'}
                        icon={<Icon as={FcTreeStructure} w={10} h={10} />}
                        description={
                            'All your data will be safe and encrypted on our servers, do not worry.'
                        }
                    />
                </Flex>
            </Container>
        </Box>
    );
}