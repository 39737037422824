import { Box, Heading, Table, TableCaption, TableContainer, Tbody, Thead, Tr, VStack, Text, Flex, Th, Td, Tfoot, Badge } from "@chakra-ui/react";

export function Payments({ payments }) {
    return (
        <>
            <Box width="100%" overflowX="auto" py={12}>
                <VStack spacing={2} textAlign="center" paddingBottom={{ base: 4, md: 12 }}>
                    <Heading as="h1" fontSize={{ base: "2xl", md: "4xl" }}>
                        Your Payments
                    </Heading>
                    <Text fontSize={{ base: "md", md: "lg" }} color={"gray.500"}>
                        Here you have a list of all your payments that you have made on our site along with some information.
                    </Text>
                </VStack>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Amount</Th>
                            <Th>Keys</Th>
                            <Th>Date</Th>
                            <Th>Gateway</Th>
                            <Th>ID</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {payments ? payments.map((payment) => {
                            return (
                                <Tr key={payment.id}>
                                    <Td>${payment.amount}</Td>
                                    <Td>{payment.keysAmount}</Td>
                                    <Td>{new Date(payment.createdAt).toLocaleDateString()}</Td>
                                    <Td>{payment.gateway.charAt(0).toUpperCase() + payment.gateway.slice(1)}</Td>
                                    <Td>{payment.id}</Td>
                                    <Td>
                                        <Badge variant='solid' colorScheme={payment.status === 'paid' ? 'green' : payment.status === 'pending' ? 'yellow' : 'red'}>
                                            {payment.status}
                                        </Badge>
                                    </Td>
                                </Tr>
                            )
                        }) : <></>}
                    </Tbody>
                </Table>
            </Box>

        </>

    )
}