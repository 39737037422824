import { Box, Heading, Table, TableCaption, TableContainer, Tbody, Thead, Tr, VStack, Text, Flex, Th, Td, Tfoot, Badge } from "@chakra-ui/react";

export function Upgrades({ upgrades }) {
    return (
        <>
            <Box width="100%" overflowX="auto" py={12}>
                <VStack spacing={2} textAlign="center" paddingBottom={{ base: 4, md: 12 }}>
                    <Heading as="h1" fontSize={{ base: "2xl", md: "4xl" }}>
                        Your Upgrades
                    </Heading>
                    <Text fontSize={{ base: "md", md: "lg" }} color={"gray.500"}>
                        Here you have a list of all your upgrades that you have made on our site along with some information.
                    </Text>
                </VStack>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Country</Th>
                            <Th>Date</Th>
                            <Th>Email</Th>
                            <Th>ID</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {upgrades ? upgrades.map((upgrade) => {
                            return (
                                <Tr key={upgrade.id}>
                                    <Td>{upgrade.country}</Td>
                                    <Td>{new Date(upgrade.upgradedAt).toLocaleDateString()}</Td>
                                    <Td>{upgrade.email}</Td>
                                    <Td>{upgrade.id}</Td>
                                    <Td>
                                        <Badge variant='solid' colorScheme={upgrade.status === 'Upgraded' ? 'green' : upgrade.status === 'Upgrading' ? 'yellow' : 'red'}>
                                            {upgrade.status}
                                        </Badge>
                                    </Td>
                                </Tr>
                            )
                        }) : <></>}

                    </Tbody>
                </Table>
            </Box >

        </>

    )
}